<template>
  <div :class="appClass">
    <!-- Navbar -->
    <div class="container navbar-container">
      <nav class="navbar navbar-expand-lg navbar-dark custom-navbar">
        <div class="container-fluid">
          <router-link class="navbar-brand" to="/">
            <img
              alt="Logo"
              class="logo"
              width="192"
              height="31.6"
              src="./assets/minified/logo.webp"
            />
          </router-link>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarMain"
            aria-controls="navbarMain"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarMain">
            <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <router-link
                  class="nav-link btn-hover"
                  title="Stake"
                  aria-current="page"
                  exact-active-class="active-link"
                  exact
                  to="/stake"
                  >Stake</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link btn-hover"
                  title="Validators"
                  aria-current="page"
                  exact-active-class="active-link"
                  exact
                  to="/validators"
                  >Validators</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link btn-hover"
                  title="Blog"
                  to="/blog"
                  exact-active-class="active-link"
                  >Blog</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link btn-hover me-0"
                  title="Company"
                  to="/company"
                  exact-active-class="active-link"
                  >Company</router-link
                >
              </li>
            </ul>

            <!-- Right Items -->
            <div class="d-flex right-nav-items">
              <ul class="list-inline d-flex align-items-center mb-0">
                <li class="list-inline-item d-none d-sm-block">
                  <router-link
                    class="list-inline-item fancy-button-border btn-hover"
                    title="Stake"
                    aria-current="page"
                    to="/stake"
                    >Stake now</router-link
                  >
                </li>
                <li
                  class="list-inline-item connected-wallet-button d-none d-sm-block"
                >
                  <div
                    v-if="this.$store.state.walletConnected"
                    class="dropdown"
                  >
                    <a
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      class="fancy-button-border me-0 btn-hover fixed-width"
                      title="Wallet"
                      @click="connect_wallet"
                      href="#connect"
                      >{{ buttonLabel() }}
                      <span class="caret-style ms-1">☰</span>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-element mt-3 w-100">
                      <li>
                        <button
                          @click="copy_address"
                          class="dropdown-item text-center"
                          type="button"
                        >
                          Copy address
                        </button>
                      </li>
                      <li>
                        <button
                          @click="disconnect"
                          class="dropdown-item text-center"
                          type="button"
                        >
                          Disconnect
                        </button>
                      </li>
                    </ul>
                  </div>
                  <a
                    v-else
                    class="list-inline-item me-0 fancy-button-border btn-hover fixed-width"
                    @click="connect_wallet"
                    title="Connect Wallet"
                    href="#connect"
                    >{{ buttonLabel() }}
                    <span class="caret-style ms-2"></span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>

      <!-- Add Connect Wallet button for mobile -->
      <div class="d-flex justify-content-end" id="walletMobile">
        <div class="connect-wallet-mobile fancy-button-border">
          <div class="connected-wallet-button">
            <div v-if="this.$store.state.walletConnected" class="dropdown">
              <a
                data-bs-toggle="dropdown"
                aria-expanded="false"
                class="me-0 btn-hover fixed-width"
                @click="connect_wallet"
                href="#connect"
                >{{ buttonLabel() }}
                <span class="caret-style ms-1">☰</span>
              </a>
              <ul class="dropdown-menu dropdown-menu-element mt-3">
                <li>
                  <button
                    @click="copy_address"
                    class="dropdown-item text-center"
                    type="button"
                  >
                    Copy address
                  </button>
                </li>
                <li>
                  <button
                    @click="disconnect"
                    class="dropdown-item text-center"
                    type="button"
                  >
                    Disconnect
                  </button>
                </li>
              </ul>
            </div>
            <a
              v-else
              class="me-0 btn-hover"
              @click="connect_wallet"
              href="#connect"
              >{{ buttonLabel() }}
              <span class="caret-style ms-2"></span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- Router View -->
    <router-view></router-view>
  </div>
</template>

<script>
import { Connection } from "@solana/web3.js";
import { API_BASE_URL, NETWORK } from "./constants";
import { mapState } from "vuex";

const getProvider = () => {
  if ("phantom" in window) {
    const provider = window.phantom?.solana;

    if (provider?.isPhantom) {
      console.log("Found the phantom provider");
      return provider;
    } else {
      console.log("not found wallet");
      // window.open("https://phantom.app/", "_blank");
    }
    return null;
  }

  return null;
};

export default {
  name: "App",
  data() {
    return {
      appClass: "",
      copied: false,
      connecting: false,
    };
  },
  created() {
    this.setAppClass(this.$route);
  },
  watch: {
    $route(to) {
      this.setAppClass(to);
    },
  },
  methods: {
    setAppClass(route) {
      this.appClass = route.meta.appClass || "";
    },
    async connect_wallet() {
      this.connecting = true;
      await this.$store.dispatch("connect_wallet");
      this.connecting = false;
    },
    buttonLabel() {
      let key = this.$store.state.connectedWalletKey || "";

      return this.copied
        ? "Address copied"
        : this.$store.state.walletConnected && key
        ? key?.substring(0, 6) + "..." + key?.substring(key.length - 6)
        : this.connecting
        ? "Connecting..."
        : "Connect Wallet";
    },
    async getTokenSolValue() {
      try {
        const tokenvalue_response = await fetch(API_BASE_URL + "/tokenvalue", {
          method: "GET",
        });
        let tokenvalue_data = await tokenvalue_response.json();
        this.$store.commit("setTokenValue", tokenvalue_data?.tokenValue || 0);
      } catch (error) {
        console.log("Error while getting token value", error);
      }
      try {
        const response = await fetch(API_BASE_URL + "/solvalue", {
          method: "GET",
        });

        let data = await response.json();
        this.$store.commit("setSolValue", data?.solValue || 0);
      } catch (error) {
        console.log("Error while getting token value", error);
      }

      try {
        const response1 = await fetch(API_BASE_URL + "/epochinfo", {
          method: "GET",
        });

        let data1 = await response1.json();
        // console.log("setEpochSeconds", data1);
        this.$store.commit("setEpochSeconds", data1?.epochSeconds || 0);
        console.log("data1.epoch", data1?.epoch);
        this.$store.commit("setEpochValue", data1?.epoch || 0);
      } catch (error) {
        console.log("Error while getting token/sol value", error);
      }
    },
    disconnect() {
      this.$store.state.provider.disconnect();
    },

    copy_address() {
      navigator.clipboard
        .writeText(this.$store.state.provider.publicKey.toString())
        .then(() => {
          this.copied = true;
          setTimeout(() => {
            this.copied = false;
          }, 1000);
        });
    },
  },
  async mounted() {
    this.connecting = true;
    this.$store.commit(
      "setConnectedWalletKey",
      this.$store.state.provider?.publicKey || ""
    );
    this.getTokenSolValue();
    this.$store.state.provider = getProvider();
    const connection = new Connection(NETWORK);
    this.$store.state.connection = connection;

    if (this.$store.state.provider) {
      // console.log("this.$store.state.provider", this.$store.state.provider);
      let isConnected = this.$store.state.provider.isConnected;
      // console.log("isConnected 1", isConnected);
      if (!isConnected) {
        // console.log("isConnected NOT", isConnected);
        try {
          await this.$store.state.provider.connect({ onlyIfTrusted: true });
        } catch (error) {
          // console.log("Error", error);
        }
      }
      isConnected = this.$store.state.provider.isConnected;
      // console.log("isConnected 2", isConnected);
      // console.log(
      //   "setConnectedWalletKey ",
      //   this.$store.state.provider.publicKey.toString()
      // );
      this.walletConnected = isConnected;
      this.$store.commit("setWalletConnected", isConnected);
      this.$store.commit(
        "setConnectedWalletKey",
        this.$store.state.provider.publicKey?.toString()
      );
      // console.log("isConnected", isConnected);
      // if (!isConnected) {
      //   console.log("connecting...");
      //   await this.$store.state.provider.connect();
      //   this.walletConnected = true;
      // } else {
      //   this.walletConnected = this.$store.state.provider.isConnected;
      //   }
      this.walletConnected = this.$store.state.provider.isConnected;
      this.$store.state.provider.on("connect", () => {
        console.log("Wallet Connected");
        console.log(
          `Wallet isConnected: ${this.$store.state.provider.isConnected}`
        );
        // console.log(
        //   `Public Key: ${this.$store.state.provider.publicKey.toString()}`
        // );
      });

      this.$store.state.provider.on("disconnect", () => {
        this.$store.state.walletConnected = false;
        console.log("Wallet Disconnected");
      });
    }
    this.connecting = false;
  },
  // watch: {
  //   "this.$store.state.provider": {
  //     handler(newFilter, oldFilter) {
  //       this.$store.commit(
  //         "setConnectedWalletKey",
  //         this.$store.state.provider?.publicKey || ""
  //       );
  //     },
  //     immediate: true, // Trigger the handler immediately when the component is created
  //   },
  // },
  computed: {
    ...mapState({
      walletConnected: (state) => state.walletConnected,
      provider: (state) => state.provider,
    }),
  },
};
</script>

<style scoped>
/* Add your custom styles here */
.company {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  background-image: url("assets/minified/company_bg.webp");
}
</style>
