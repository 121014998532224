export const API_BASE_URL = "https://api.rakurai.io/api";
export const INTERNAL_API =
  process.env.VUE_APP_API_BASE_URL || "https://rakurai.io/xapi";
export const NETWORK = "https://api.testnet.solana.com";

export const INFLUX = {
  DB: "metrics",
  TABLE: "validator",
  BASE_URL: "https://api.rakurai.io:8086",
  USERNAME: "metrics_read",
  PASSWORD: "read",
};
