import Vue from "vue";
import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: { appClass: "home" },
  },
  {
    path: "/stake",
    name: "stake",
    component: () =>
      import(/* webpackChunkName: "stake" */ "../views/StakeView.vue"),
    meta: { appClass: "stake staticBackground layoutfix", title: "Stake Now" },
  },
  {
    path: "/blog",
    name: "blog",
    component: () =>
      import(/* webpackChunkName: "blog" */ "../views/BlogView.vue"),
    meta: { appClass: "blog otherBackground layoutfix", title: "Blog" },
  },
  {
    path: "/company",
    name: "company",
    component: () =>
      import(/* webpackChunkName: "company" */ "../views/CompanyView.vue"),
    meta: { appClass: "company otherBackground layoutfix", title: "Company" },
  },
  {
    path: "/privacy-policy",
    name: "privacy",
    component: () =>
      import(/* webpackChunkName: "privacy" */ "../views/PrivacyView.vue"),
    meta: {
      appClass: "privacy otherBackground layoutfix",
      title: "Privacy Policy",
    },
  },
  {
    path: "/terms",
    name: "terms",
    component: () =>
      import(/* webpackChunkName: "terms" */ "../views/TermsView.vue"),
    meta: { appClass: "terms otherBackground layoutfix", title: "Terms" },
  },
  {
    path: "/faq",
    name: "faq",
    component: () =>
      import(/* webpackChunkName: "terms" */ "../views/FAQView.vue"),
    meta: { appClass: "faq otherBackground layoutfix", title: "FAQ" },
  },
  {
    path: "/transaction",
    name: "transaction",
    component: () =>
      import(/* webpackChunkName: "terms" */ "../views/TransactionView.vue"),
    meta: {
      appClass: "transaction otherBackground layoutfix",
      title: "Transaction",
    },
  },
  {
    path: "/validators",
    name: "validators",
    component: () =>
      import(/* webpackChunkName: "terms" */ "../views/ValidatorsView.vue"),
    meta: {
      appClass: "nodeOperator otherBackground layoutfix",
      title: "Validators",
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((toRoute, fromRoute, next) => {
  window.document.title =
    toRoute.meta && toRoute.meta.title ? toRoute.meta.title : "Home";
  next();
});

export default router;
